<template>
  <div>
    <div class="card mb-5 mt-sm-5">
      <div class="card-body">
        <div v-if="filtersConfig.length" class="row">
          <div class="col-md-3">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="computedDealsFilter.title"
                :value="computedDealsFilter.options.filter(el => filters.deal_id.includes(el.id))"
                :options="computedDealsFilter.options"
                :multiple="true"
                @select="filters.deal_id.push($event.id)"
                @remove="filters.deal_id = filters.deal_id.filter(el => el !== $event.id)"
            />
          </div>
          <div class="col-md-3">
            <DatetimePickerFormGroup
                :label="computedPayBeforeFilter.title"
                :value="filters.pay_before"
                :with-icon="false"
                allow-clear
                :model-config="{type: 'string', mask: 'DD.MM.YYYY'}"
                @change="filters.pay_before = $event"
            />
          </div>
          <div class="col-md-3">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="computedStatusesFilter.title"
                :value="computedStatusesFilter.options.filter(el => filters.status_id.includes(el.id))"
                :options="computedStatusesFilter.options"
                :multiple="true"
                @select="filters.status_id.push($event.id)"
                @remove="filters.status_id = filters.status_id.filter(el => el !== $event.id)"
            />
          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-success font-weight-bold" @click="selectUnpaidStatuses">
              {{ $t('btn.show_unpaid_invoices') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-7">
      <div class="card-body p-0">
        <BootstrapVueTable
            request-url="api/payments/table"
            module-name="payments"
            :filters="computedFilters"
            :update-data="updateTableFlag"
            with-pagination
            with-footer
        />
      </div>
    </div>
  </div>
</template>

<script>
import BootstrapVueTable from '@/components/elements/tables/BootstrapVueTable';
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

export default {
  components: {
    BootstrapVueTable,
    DatetimePickerFormGroup,
    MultiselectFormGroup
  },
  data: () => ({
    filters: {
      deal_id: [],
      status_id: [],
      pay_before: null,
    },
    filtersConfig: {},
    filtersLoaded: false,
    updateTableFlag: false,
  }),
  async beforeMount() {
    this.filtersConfig = await this.$store.dispatch('tableStore/GET_FILTERS', 'payments');
  },
  computed: {
    computedDealsFilter() {
      return this.filtersConfig.length ? this.filtersConfig.find(el => el.code === 'deal') : null;
    },
    computedPayBeforeFilter() {
      return this.filtersConfig.length ? this.filtersConfig.find(el => el.code === 'pay_before') : null;
    },
    computedStatusesFilter() {
      return this.filtersConfig.length ? this.filtersConfig.find(el => el.code === 'status') : null;
    },
    computedFilters() {
      let result = {};

      for (let key in this.filters) {
        if (this.filters.hasOwnProperty(key) && this.filters[key]) {
          if (Array.isArray(this.filters[key]) && !this.filters[key].length) {
            continue;
          }
          if (!Array.isArray(this.filters[key]) && typeof this.filters[key] === 'object' && !this.filters[key].from && !this.filters[key].to) {
            continue;
          }
          result[key] = this.filters[key];
        }
      }

      return result;
    }
  },
  watch: {
    filtersConfig(data) {
      if (data) {
        setTimeout(() => {
          this.filtersLoaded = true;
        }, 100);
      }
    },
    filters: {
      handler() {
        if (this.filtersLoaded) {
          this.updateTableFlag = true;
          setTimeout(() => {
            this.updateTableFlag = false;
          }, 1000);
        }
      },
      deep: true
    },
  },
  methods: {
    selectUnpaidStatuses() {
      const unpaidOptions = this.computedStatusesFilter.options.filter(el => el.id !== 'paid').map(el => el.id);
      this.$set(this.filters, 'status_id', unpaidOptions);
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;
  margin-bottom: 0 !important;

  & .form-group__label {
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    z-index: 2;
  }
}
</style>